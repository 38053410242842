@import "../../../../theme/colors/";
@import "../../../../app.scss";
@import "../../../../theme/fonts/";
 
.indicator-scroll{
    position: fixed;
    bottom: 2%;
    left: 75%;
transform: translateX(-75%);
    cursor: pointer;
}
.indicator-body{
    background-color: white;
    border-radius: 360px;
    width: 62px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}