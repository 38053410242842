@import "../../../../theme/colors/";
@import "../../../../app.scss";
@import "../../../../theme/fonts/";

.plan-card {
  border-radius: $borderRadius !important;
  border: 1px solid $borderColorLight;
  background: $white;
  width: 90%;
  padding: 16px;

  p {
    color: $textColor;
    font-size: $fontSizeForText;
    font-weight: $fontWeightForText;
    padding: 0px !important;
    margin: 0px !important;
    text-align: left;
  }
  h2 {
    font-size: 2rem;
    font-weight: 700;
    padding: 0px !important;
    margin: 0px !important;
  }
  h3 {
    font-size: $fontSizeForHeading;
    font-weight: $fontWeightForText;

    color: $textColor;
    padding: 0px !important;
    margin: 0px !important;
  }
}

.agencyplan-card {
  border-radius: $borderRadius !important;
  background: $white;
  width: 90%;
  padding: 8px;

  background: linear-gradient(
    132deg,
    rgba(58, 168, 180, 1) 1%,
    rgba(131, 58, 180, 1) 32%,
    rgba(253, 29, 29, 1) 62%,
    rgba(252, 176, 69, 1) 100%
  );
  background-size: 220% 220%;
  animation: moveGradient 6s alternate infinite;

  @keyframes moveGradient {
    50% {
      background-position: 200% 30%;
    }
  }

  p {
    color: $textColor;
    font-size: $fontSizeForText;
    font-weight: $fontWeightForText;
    padding: 0px !important;
    margin: 0px !important;
    text-align: left;
  }
  h2 {
    font-size: 3rem;
    font-weight: 700;
    padding: 0px !important;
    margin: 0px !important;
  }
  h3 {
    font-size: $fontSizeForHeading;
    font-weight: $fontWeightForText;
    color: $textColor;
    padding: 0px !important;
    margin: 0px !important;
  }
}

.ant-ribbon {
  top: 105px !important;
  right: 36px !important;
  @media (max-width: 767px) {
    right: 100px !important;

}
}
