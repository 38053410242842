@import "../../../theme/fonts/";
@import "../../../theme/colors/";

.avatar-img {
  height: 80px !important;
  width: 80px !important;
  min-width: 32px !important;
  background: $bgColorGradient !important;
}
.headings{
  h1 {
    font-size: $fontSizeForHeading !important;
    padding: 0px !important;
    margin: 0px !important;
    font-weight: $fontWeightForSubHeading !important;
    font-family: $fontFamily !important;
    @media (max-width: 670px) {
     font-size: $fontSizeForMobileHeading !important;
  }
  }
  h2 {
    font-size: $fontSizeForSubHeading !important;
    padding: 0px !important;
    margin: 0px !important;
    font-weight: $fontWeightForText !important;
    font-family: $fontFamily !important;
  }
}

@media (max-width: 500px) {
  .avatar-img {
    height: 40px !important;
    width: 40px !important;
  }
}
