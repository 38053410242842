@import "../../../theme/colors/";
@import "../../../theme/fonts/";
@import "../../../app.scss";
$HeadingBackgroundCol: #f0f2f5;

.header {
  position: sticky;
  width: 100%;
  top: 0;
  border-bottom: 2px solid $borderColorLight;
  padding: 10px 10px 10px 10px;
  background-color: "#f0f2f5";
  background: #f0f2f5;
}
.description {
  color: $textColor;
  // padding: 0 !important;
  // margin: 0 !important;
}

.query {
  font-size: $fontSizeForText;
}

.height_scroll {
  // background-color: red;
  height: 100%;
  position: absolute;
  width: 100%;
  overflow-y: scroll;
}

.content_greation_container {
  width: 100% !important;
}

.footer {
  background: #eff2f5;
  border-top: 2px solid $borderColorLight;
  border-right: 2px solid $borderColorLight;
}

.links-setting {
  background-color: $HeadingBackgroundCol !important;
}
.menuDiv {
  border-bottom: 1px solid rgba(14, 21, 38, 0.1) !important;
}
.setting-links {
  font-family: $fontFamily !important;
  font-size: 12px !important;
  font-weight: $fontWeightForText !important;
  background-color: $HeadingBackgroundCol !important;
  color: $textColor !important;
  margin-bottom: 0px !important;
  min-width: fit-content !important;
  &:hover {
    background-color: $HeadingBackgroundCol !important;
    color: $textColor !important;
  }
  // &:focus {
  //   background-color: $HeadingBackgroundCol !important;
  //   color: $textColor !important;
  //   border-bottom: 2px solid $themeColor !important;
  // }
}

.active {
  background-color: $HeadingBackgroundCol !important;
  color: $textColor !important;
  border-bottom: 2px solid $themeColor !important;
}

.setting-page {
  width: 100% !important;
  background-color: $HeadingBackgroundCol !important;
}

.ant-popover-placement-bottom .ant-popover-arrow {
  left: 70% !important;
}
.cards {
  display: flex;
  flex-direction: column;
}
// .next__prev {
//   height: 40px;
//   width: 100px;
//   display: flex;
//   justify-content: space-between;
// }

.min-wd-15 {
  min-width: 15px;
}

.container-paginate {
  width: 89%;
}
.active_link {
  background-color: white;
  height: fit-content;
  padding: 2px;
  box-sizing: border-box;
  margin-top: -2px;
}
.pageClass {
  color: $themeColor !important;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding-bottom: 10px;
}
