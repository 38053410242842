@import "../../../../theme/colors/";
@import "../../../../app.scss";
@import "../../../../theme/fonts/";

.card-item {
  border-radius: $borderRadius !important;

  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 14px rgba(14, 21, 38, 0.1) !important;
    // border: 2px solid $themeColor !important;
  }
  .card-heading {
    font-size: $fontSizeForSubHeading !important;
  }
  p {
    font-size: $fontSizeForText;
    font-weight: $fontWeightForText;
  }
}

.customHeight {
  height: 300px !important;
}
.heartIconDiv {
  color: #5f636e;
  &:hover {
    transition: all 250ms;
    box-shadow: $boxShadow !important;
    color: red !important;
  }
}
.FavouriteIcon {
  font-size: 20px !important;
  color: inherit !important;
  opacity: "0.8" !important;
  // &:hover {
  //   color: red !important;
  // }
}

@media only screen and (max-width: 1250px) {
  .customHeight {
    height: auto !important;
  }
}
