@import "../../../theme/colors/";
@import "../../../theme/fonts/";
@import "../../../app.scss";

h1 {
  font-size: $fontSizeForHeading;
  font-weight: $fontWeightForHeading;
}

.carousel {
  height: 240px !important;
  text-align: center !important;
  border-radius: $borderRadius !important;
  @media (max-width: 767px) {
    height: 100px !important;
  }

}

.divider {
  margin-top: 2px !important;
}

.rightCol {
  border-radius: $borderRadius;
  color: $white;
  h3 {
    color: $white;
  }
  p {
    color: floralwhite;
  }
}
.favorite-card-main {
  background-color: $white;
  border-radius: $borderRadius;
  width: 100%;
}
.favorite-card {
  display: flex;
  justify-content: "center";
  align-items: center;
  text-align: center;
  min-height: 250px;
  width: 100%;
}
.favorite-cards {
  display: flex;
  // justify-content: "center";
  // align-items: center;
  // text-align: center;
  min-height: 250px;
  width: 100%;

}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  font-size: 30px !important;
  padding: 1px !important;
  z-index: 1 !important;
  color: $white !important;
  right: 20px !important;
  top: 45% !important;
}

.ant-carousel .slick-prev {
  left: 10px !important;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: white !important;
}

.plusIcon {
  color: cadetblue !important;
  :hover {
    color: $themeColor !important;
  }
}
.para {
  color: $textColor !important;
}

.button-copy {
  :active {
    transform: translate(0.5px);
    -webkit-animation: slide-up 0.15s cubic-bezier(0.51, 0.92, 0.265, 1.55) both;
    animation: slide-up 0.15s cubic-bezier(0.51, 0.92, 0.265, 1.55) both;
  }
}
