.imgDiv{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.container-show-Case{
    width: 31%;
    @media (max-width: 670px) {
        width: 90%;

    }
    // max-height: 580px;
}

.haeder-container-showcase{
    width: 94%;
    @media (max-width: 670px) {
        margin: 16px;
    }
}

.generat-showcase{
    display: flex;
    align-items: center;
    height: 50px;
    padding: 5px;
}

.btn-showcase{
    border-radius: 4px;
    margin-right: 10px;
    padding: 10px;
    cursor: pointer;
}
.btn-showcase-activ{
    background-color: #60114A;
    border: #c3bdcf 1px solid;
    color: white;
    padding-left: 10px;
    padding-right: 10px;

}
.btn-showcase>p{
    padding: 0px !important;
    margin: 0;
}

.container-btn-showcase-header{
    background-color: #ffffff;
    border-radius: 10;
    @media (max-width: 670px) {
        margin-left: 24px;
        width: 100% !important;

    }
}

.capitalize{
    text-transform: capitalize;
}

.search-main-contianer{
    width: 68%;
    display: flex;
    @media (max-width: 670px) {
        width: 90% !important;
    }
}

.search-input-main-container{
    width: 28%;
    @media (max-width: 670px) {
        width: 100% !important;
        margin-left: 28px;
        margin-top: 16px !important;
    }
}
.mobileDropdown{
    display: none;
    @media (max-width: 767px) {
        display: block;
    }
}
.dropdown{
    @media (max-width: 767px) {
        display: none !important;
    }
}