@import "../../../../theme/fonts/";
@import "../../../../theme/colors/";
@import "../../../../app.scss";

.card {
  width: 500px;
  background: $white;
  box-shadow: $boxShadow;
  border-radius: $borderRadius;
 
  h1 {
    text-align: center;
    @media (max-width:767px){
      font-size: 24px !important;
    }
  }
  @media (max-width:767px){
    width:360px !important;
  }
}
.max-width {
  max-width: 100vw;
}
.screen-h {
  height: 80vh !important;
  
}

.signInBtn{
  color: $themeColor;
}
