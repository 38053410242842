@import "../../../../theme/colors/";
@import "../../../../theme/fonts/";
.card {
  width: 500px;
  background: $white;
  box-shadow: $boxShadow;
  h1 {
    text-align: center;
    font-size: $fontSizeForHeading;
  }
  p {
    text-align: center;
    font-size: $fontSizeForText;
    color: $textColor;
  }
}
