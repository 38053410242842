@import "../../../../theme/colors/";
@import "../../../../theme/fonts/";
@import "../../../../app.scss";

.ant-table-tbody > tr > td {
  text-align: center !important;
}

.ant-table-thead > tr > th {
  text-align: center !important;
}

.ant-pagination-item a {
  background-color: $themeColor !important;
  color: $white !important;
  box-shadow: none !important;
  border: none !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background-color: $themeColor !important;
  color: $white !important;
  border: none !important;
}

.ant-pagination-item {
  border: none !important;
}
