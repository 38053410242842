@import "../../../theme/colors/";
@import "../../../theme/fonts/";
@import "../../../app.scss";

h1 {
  font-size: $fontSizeForHeading;
  font-weight: $fontWeightForHeading;
}

.brain {
  background: linear-gradient(
    132deg,
    rgba(58, 168, 180, 1) 1%,
    rgba(131, 58, 180, 1) 32%,
    rgba(253, 29, 29, 1) 62%,
    rgba(252, 176, 69, 1) 100%
  );
  background-size: 220% 230%;
  animation: moveGradient 4s alternate infinite;
}

@keyframes moveGradient {
  50% {
    background-position: 200% 30%;
  }
}
.ddd {
  :hover {
    border: 1px solid black;
  }
}
