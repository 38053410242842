@import "../../../../theme/colors";
@import "../../../../app.scss";
@import "../../../../theme/fonts/";

.btn {
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  width: 100%;
  text-align: center;
  padding: 8px 18px;
}

.btn[disabled] {
  opacity: 0.8 !important;
  cursor: not-allowed !important;
}
.ant-spin-dot-item{
background-color: white !important;
}