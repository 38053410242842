$white: #fff;
$themeColor: #60114a;
$bgColorGradient: linear-gradient(
  159deg,
  rgba(0, 66, 96, 1) 0%,
  rgba(7, 28, 59, 1) 28%,
  rgba(9, 18, 49, 1) 52%,
  rgba(51, 18, 61, 1) 69%,
  rgba(96, 17, 74, 1) 100%
);
$textColor: #717171;
$headinColor: #383f51;
$boxShadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
$boxshadowForInputs: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
$grey: #9799a0;
$graphBackground: #121826;
$graphHeadingTextColor: #d1d1d1;
$black: #000;
$inputBtnColor: #06c7e8;
$headingColorGreen: #2aff8c;
