@import "../../../../theme/fonts/";
@import "../../../../theme/colors/";

.signup {
  font-family: $fontFamily !important;
}

.title {
  font-size: $fontSizeForHeading;
  font-weight: $fontWeightForSubHeading;
  font-family: $fontFamily !important;
}
.subtitle {
  font-size: $fontSizeForSubHeading;
  font-family: $fontFamily !important;
  color: $textColor;
}
.main-content-signup {
  background: $bgColorGradient;
}
.side-contents {
  min-height: 100vh !important;
  text-align: center !important;
}
.LoginBtn{
  color: $themeColor;
}