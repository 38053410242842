
.ant-spin-dot-item {
    background-color: #ffffff;
  }
  
  .ant-desigin-ns > .ant-spin-dot > .ant-spin-dot-item  {
    background-color: black !important;
  }

  .ant-desigin-ns{
    position: absolute !important; 
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }

  .ant-d-spin> .ant-spin-dot > .ant-spin-dot-item {
    background-color: black !important;
  }