@import "../../../theme/colors/";
@import "../../../theme/fonts/";
@import "../../../app.scss";

.headingPara {
  font-size: $fontSizeForText;
  font-weight: $fontWeightForText;
  color: $white;
}
.amountPara {
  font-size: $fontSizeForHeading;
  font-weight: $fontWeightForText;
  color: $white;
}
.white {
  color: $white;
}
.topCard {
  width: 100%;
  height: 200px;
  background-color: $graphBackground;
  border-radius: $borderRadius;
}
.progressDiv {
  background-color: $grey;
  height: 7px;
  border-radius: 5px;
}
