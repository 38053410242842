@import "../../theme/fonts/";
@import "../../theme/colors/";
@import "../../app.scss";

.meun-li {
  font-family: $fontFamily !important;
  font-size: $fontSizeForText !important;
  color: $themeColor !important;
  background-color: transparent !important;
  display: flex !important;
  align-items: center !important;
  &:hover {
    background-color: rgba(14, 21, 38, 0.1) !important;
    color: $themeColor !important;
  }
  &:focus {
    background-color: rgba(14, 21, 38, 0.1) !important;
    color: $themeColor !important;
  }
}

.links-comp {
  color: $themeColor !important;
  background-color: transparent !important;
  font-size: $fontSizeForText !important;
  margin-left: 8px !important;
}
.sidebar-icons {
  font-size: $fontSizeForText !important;
}
.menus-lists {
  border-right: none !important;
  :hover {
    color: $themeColor !important;
  }
  :focus {
    color: $themeColor !important;
  }
}
.ant-menu-item:hover {
  color: $themeColor !important;
}
.customColor {
  color: #5f636e !important;
}

.ant-menu-item-active {
  color: $themeColor !important;
}

.ant-menu-items-selected {
  background-color: rgba(14, 21, 38, 0.1) !important;
  color: $themeColor !important;
  border-right: 6px solid $themeColor !important;
}
.notselected {
  color: #5f636e !important;
  border-right: 1px solid white !important;
}
.displaySideba {
  @media (max-width: 767px) {
    display: none;
}
}
.ant-menu{
  @media (max-width: 767px) {
    background: none !important;
}
}
.collapsed-content-layout{
  @media (max-width: 767px) {
    margin-left: 0 !important;
  }
}
.mobileNav{
  display: none !important;
  @media (max-width: 767px) {
    display: block !important;
  }
}
.navBar{
  background-color: #60114a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
}

.logo{
  font-size: 25px;
  color: white;
  font-weight: 500;
}
// .navLinks{
//   display: flex;
// }

.navLinks li{
  list-style-type: none;
}

.link{
  color:white;
  text-decoration: none;
  font-size: 20px;

}

.link:hover {
  color: orange ;
}

.icon{
  display: none;
}

.active{
  color: orange;
}

@media screen and (max-width:900px){
.navLinks{
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 88;
  top: 70px;
  right: -300px;
  width: 250px;
  height: 100vh;
  background-color: #60114a;
  transition: 1s ease;
}

.active{
  right: 0px;
}


.link{
  line-height: 50px;
}

.icon{
  display: block;
}
}

