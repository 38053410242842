@import "../../../theme/colors/";

.bg-bubble{
    background-color: #e5e6ea;
    width: fit-content;
    color: black;
    padding: 2px 8px 2px 8px;
    border-radius: 4px;
    margin: 0px 10px 10px 10px;
    border-radius: 12px;
    transition: 0.5s;
    // position: relative;
    // overflow: hidden;
    // z-index: 1;
    // box-shadow: 6px 6px 12px #c5c5c5,
    //             -6px -6px 12px #ffffff;
}

// .bg-bubble::before {
//     content: '';
//     width: 0;
//     height: 100%;
//     border-radius: 30em;
//     position: absolute;
//     top: 0;
//     left: 0;
//     // background-image: $bgColorGradient;
//     background-color: $themeColor;
//     transition: .5s ease;
//     display: block;
//     z-index: -1;
// }

.bg-bubble:hover{
    color: #e5e6ea;
    background-color: $themeColor;
    transform: scale(1.05);
}

// .bg-bubble:hover::before {
//     width: 100%;
// }

.bg-bubble>p{
    margin: 0;
}