@import "../../../theme/colors/";
@import "../../../theme/fonts/";
@import "../../../app.scss";

h1 {
  font-size: $fontSizeForHeading;
}
.mobImg{
  @media only screen and (max-width: 767px) {
    height: 80vh;
  }
}
.videoCard {
  border-radius: $borderRadius !important;
  width: 270px;
}
.ant-card-body {
  padding: 20px !important;
}

.ant-card-cover img {
  border-radius: $borderRadius $borderRadius 0 0 !important;
  height: 150px !important;
}

.creater {
  margin-top: -14px;
  font-weight: 500;
}
.logoAvatar {
  margin-left: 1px !important;
  margin-top: -5px !important;
  border: 1px solid $themeColor;
}

.videoFooter {
  margin-top: 30px;
  font-size: smaller;
}

.info {
  margin-left: 5px;
}

.ant-card-meta-detail > div {
  margin-bottom: auto !important;
}

.descriptions {
  height: 30px !important;
  white-space: nowrap !important;
}
