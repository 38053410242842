@import "../../theme/colors/";
@import "../../theme/fonts/";
@import "../../app.scss";

.navbar {
  position: fixed !important;
  top: 0px !important;
  width: 100% !important;
  z-index: 1 !important;
  background-color: $white !important;
}
.ant-layout-sider {
  background-color: $white !important;
  @media (max-width: 767px) {
    background: none !important;
    }
}
.ant-layout-sider-trigger {
  font-size: larger !important;
  background-color: $themeColor !important;
}
.menus-lists {
  border-right: none !important;
}

.sidebar-menu {
  position: fixed !important;
  min-height: 100vh;
  max-width: 300px !important;
  height: 100%;
  @media (max-width: 767px) {
    display: none !important;
    }
}

.uni-icon {
  min-height: fit-content !important;
  height: fit-content !important;
}
.collapsed-content-layout {
  position: relative;
  margin-left: 80px !important;
}
.content-layout {
  position: relative;
  margin-left: 200px !important;
}
.uni-icon-small {
  padding: 0px 8px !important;
}
.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 22px !important;
}

.align-self-end{
  display: flex;
  align-self: flex-end;
}

.justify-between-full{
  display: flex;
    flex-direction: column;
    height: 90vh;
    justify-content: space-between;

}