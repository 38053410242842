@import "../../../../theme/colors/";

.border-top-likes{
    border-top: 1px solid grey;
    margin-right: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    // border: 2px solid red;
}
.auto-comment-scroll{
    max-height: 200px;
    overflow: auto;
}

.comment-ipn{
    border-radius: 40px !important;
}
.comment-ipn:focus{
    border: 1px solid $themeColor !important;
    box-shadow: none !important;
}
.comment-ipn:hover{
    border: 1px solid $themeColor !important;
}

.border-send-btn{
    text-align: center;
    background-color: $themeColor;
    width: 38px;
    height: 30px;
    border-radius: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-comment-content{
    background-color: #f0f2f5;
    margin-right: 10px;
    padding: 4px;
    border-radius: 4px;
}
.semibold600{
    font-weight: 600;
}
.commentAuthorAvatar{
    border:2px solid $themeColor;
}