@import "../../../../theme/colors/index.scss";
@import "../../../../theme/fonts/";
@import "../../../../app.scss";
$HeadingBackgroundCol: #f0f2f5;
.activetab {
  background-color: rgb(229, 231, 235) !important;
  color: black;
  border-left: 5px solid $themeColor !important;
  border-radius: 5px;
  font-weight: 500;
}
.notactive {
  border: none !important;
  border-radius: 5px;
  border-left: 5px solid #f0f2f5 !important;
}

.inputdiv {
  width: auto !important;
  border: none !important;
}
.userDetails {
  color: $textColor;
  margin: 0px !important;
  padding: 0px !important;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  border-bottom: 1px solid gainsboro !important;
}
