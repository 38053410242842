@import "../../../theme/colors/";
@import "../../../theme/fonts/";
@import "../../../app.scss";

$HeadingBackgroundCol: #f0f2f5;

.heading-setting {
  font-size: $fontSizeForHeading !important;
  color: $headinColor !important;
  font-family: $fontFamily !important;
  @media (max-width: 500px) {
    font-size: $fontSizeForSubHeading !important;
  }
}
.links-setting {
  background-color: $HeadingBackgroundCol !important;
}
.setting-links {
  font-family: $fontFamily !important;
  font-size: $fontSizeForText !important;
  font-weight: $fontWeightForText !important;
  background-color: $HeadingBackgroundCol !important;
  color: $textColor !important;
  margin-bottom: 0px !important;
  min-width: fit-content !important;
  &:hover {
    background-color: $HeadingBackgroundCol !important;
    color: $textColor !important;
  }
  // &:focus {
  //   background-color: $HeadingBackgroundCol !important;
  //   color: $textColor !important;
  //   border-bottom: 2px solid $themeColor !important;
  // }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $themeColor !important;
}

.ant-tabs-ink-bar {
  background: $themeColor !important;
}

.ant-tabs-tab:hover {
  color: $themeColor !important;
}

.active {
  background-color: $HeadingBackgroundCol !important;
  color: $textColor !important;
}

.setting-page {
  width: 100% !important;
  background-color: $HeadingBackgroundCol !important;
}
.avatar-img {
  height: 80px !important;
  width: 80px !important;
  min-width: 32px !important;
  background: $bgColorGradient !important;
  margin: 0px auto !important;
}
.userName {
  font-size: $fontSizeForText;
  font-weight: $fontWeightForText;
  margin: 0px auto !important;
}

.linkedAccDiv {
  // background-color: $white;
  width: 100%;
  // box-shadow: $boxShadow;
  border-radius: $borderRadius;
}
.addBtn {
  cursor: pointer;
}

.addBtn2 {
  cursor: pointer;
  margin: 7px;
  display: flex;
  align-items: flex-end;
}

.anticon svg {
  vertical-align: inherit !important;
}

.linkedHeading {
  margin-bottom: 0px;
  font-size: $fontSizeForText;
  font-weight: $fontWeightForText;
  color: $textColor;
}

.heading {
  font-size: $fontSizeForHeading;
  text-align: center !important;
}
.subtitle {
  color: $textColor;
}

@media only screen and (max-width: 800px) {
  .inpDiv {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .formDiv {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  .inpContainer {
    width: 100% !important;
  }
  .inpParentDiv {
    justify-content: space-around !important;
  }
}
@media only screen and (max-width: 780px) {
  .inpParentDiv {
    flex-direction: column !important;
  }
  .inpContainer {
    width: 80% !important;
  }
}

.ant-tabs-top > .ant-tabs-nav {
  margin: 0px !important;
}

.settingCard {
  width: 550px;
  background: $white;
  box-shadow: $boxShadow;
  @media (max-width: 767px) {
    width: 350px !important;
}
  .head {
    h1 {
      font-size: $fontSizeForHeading !important;
      @media (max-width: 767px) {
        font-size: $fontSizeForMobileHeading!important;
    }
    }
  }
}


