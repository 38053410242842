
.root-tiktok {
    background-color: #ffff     ;
    border-radius: 4px 4px 0px 0px;
    padding: 16px 0px;
    width: 100%;
    border: 2px solid #DFDFE8;
    // opacity: 30%;
    background-image: url(../../../../assests/images/background/contentArea.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    border-bottom: none;
    height: 320px;
}

.contentArea{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
}

.footer-tiktok{
    width: 100%;
    border: 2px solid #DFDFE8;
    display: flex;
    padding: 16px;
    background: black;
    border-top: none;
    align-items: center;
    border-radius: 0px 0px 4px 4px;
    justify-content: center;
    margin-bottom: -80px;
    margin-top: 25px;
}
.image-tiktok-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 0px;
}
.image-side-tiktok{
    color: #ffff;
    padding: 5px;
    width: 35px;
    height: 30px;
    border-radius: 50%;
}

.white{ 
    color: white;
}

.side-panel-tiktok{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    margin-top: 4px;
}