@import "../../../theme/colors/";
@import "../../../theme/fonts/";
@import "../../../app.scss";

.main {
  background-color: $black;
  width: 100%;
  min-height: 100vh;
}
.subMain {
  width: 100%;
  max-width: 1600px;
}
.topHeader {
  margin: 0px auto;
}
.backTxt {
  color: $grey;
  font-size: $fontSizeForText;
  font-weight: $fontWeightForText;
}
.searchDivHeading {
  color: white;
  @media (max-width: 670px) {
    font-size: $fontSizeForHeading !important;
  }
  @media (max-width: 530px) {
    font-size: 24px !important;
  }
}

// .ant-popover-arrow-content {
//   display: none !important;
// }
.searchDivSubHeading {
  font-size: $fontSizeForText;
  color: $grey;
  @media (max-width: 530px) {
    font-size: 14px !important;
  }
}
.ant-input-affix-wrapper{
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.ant-input-search-button{
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.ant-input-search-button:focus{
  border-color: $themeColor !important;
}

.ant-input-affix-wrapper:hover{
  border-color: $themeColor !important;
}
.ant-btn-icon-only.ant-btn-lg:hover{
  border-color: $themeColor !important;
}
.inpDiv {
  width: 500px;
  height: 65px;
  border: 2px solid $grey;
  border-radius: 15px;
  @media (max-width: 670px) {
    width: 400px;
  }
  @media (max-width: 530px) {
    width: 300px;
    height: 65px;
  }
}
.inpDiv > input {
  height: 100%;
  outline: none;
  border: none;
  width: 72%;
  border-radius: 15px;
  background-color: $black;
  font-size: $fontSizeForText;
  font-weight: $fontWeightForText;
  color: $grey;
  ::placeholder {
    color: $grey;
  }
}
.ant-input-affix-wrapper-focused{
  border-color: black !important;
  box-shadow: 0 0 0 1px $themeColor !important;
}
.filters {
  background-color: #121826;
  color: white;
  border-radius: $borderRadius;
}
.inpDiv > button {
  width: 26%;
  height: 100%;
  outline: none;
  border: none;
  background-color: $inputBtnColor;
  border-radius: 12px;
  font-size: $fontSizeForText;
  font-weight: $fontWeightForText;
  color: $black;
}
.graphDivMain {
  width: 90%;
  margin: 60px auto;
}
.graphDiv {
  background-color: $graphBackground;
  border-radius: $borderRadius;
  height: 390px;
  @media (max-width: 1200px) {
    height: auto !important;
  }
}
.graphHeading {
  font-size: $fontSizeForSubHeading;
  font-weight: $fontWeightForText;
  color: $graphHeadingTextColor;
}
.graphDiv2 {
  background-color: $graphBackground;
  border-radius: $borderRadius;
  height: 500px;
}
.multipleCardsDiv {
  background-color: $black;
  border-radius: $borderRadius;
  height: 500px;
}

.RightGraph {
  background-color: $graphBackground;
  border-radius: $borderRadius;
}

.footerDiv {
  border-top: 2px solid grey;
}

.LinkTxt {
  font-size: $fontSizeForText;
  font-weight: $fontWeightForText;
  background-image: $bgColorGradient;
  background-size: 100%;
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

@media only screen and (max-width: 670px) {
  .ThirdRowMain {
    flex-direction: column !important;
  }
  .RightGraph {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .footerDiv {
    justify-content: center !important;
  }
  .FooterLogoDiv {
    border-bottom: 2px solid $grey;
    padding-bottom: 8px;
  }
  .FooterTextDiv {
    margin-top: 10px;
  }
  .single-section{
    width: 100%;
  }
}

.ant-select {
  background-color: $white !important;
  color: $textColor !important;
  border-radius: $borderRadius !important;
  outline: none !important;
  border: 2px solid $borderColorLight !important;
  box-shadow: $boxshadowForInputs;
  &:hover {
    outline: none !important;
    border: 2px solid $borderColorLight !important;
    box-shadow: $boxshadowForInputs;
  }
  &::before {
    outline: none !important;
  }
  &:focus {
    outline: none !important;
    border: 2px solid $themeColor !important;
  }

  &::placeholder {
    color: $textColor !important;
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
}

.single-section {
  width: 30%;
  margin-top: 20px;
}
.select-box {
  width: 90%;
}
.input-box-half {
  width: 40%;
}

.container-filter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.black-lable {
  color: $bgColorGradient;
}


@media only screen and (max-width: 600px) {

  .single-section{
    width: 50%;
  }
}