@import "./theme/colors";
@import "./theme/fonts";

$borderRadius: 8px;
$borderColorLight: #e5e5e5;
$border: 1px solid rgba(14, 21, 38, 0.1);
body {
  background: #eff2f5 !important;
  padding: 0 !important;
  margin: 0 !important;
}



//  Inputs Styling

.ant-input-borderless, .ant-input-borderless:hover, .ant-input-borderless:focus, .ant-input-borderless-focused, .ant-input-borderless-disabled, .ant-input-borderless[disabled]{
  background-color: $white !important;
  color: $textColor !important;
  border-radius: $borderRadius !important;
  // padding: 8px 12px !important;
  outline: none !important;
  border: 2px solid $borderColorLight !important;
  box-shadow: $boxshadowForInputs;
  &:hover {
    outline: none !important;
    border: 2px solid $borderColorLight !important;
    box-shadow: $boxshadowForInputs;
  }
  &::before {
    outline: none !important;
  }
  &:focus {
    outline: none !important;
    border: 2px solid $themeColor !important;
  }

  &::placeholder {
    color: $textColor !important;
  }
}
.ant-spin-dot-item {
  background-color: red;
}

.password-input{
  &:focus{
    outline: none !important;
    border: 2px solid #60114a !important;
  }
}

.image-body{
  object-fit: cover;
}

.image-counter{
  position: relative;
  display: inline-block;
}

.type-name{
  position: absolute;    
  text-align: center;
  height: 100%;
  width: 100%;
}

.type-name.img:before {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  content: '';
}